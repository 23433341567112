import React, { useEffect, useState } from 'react';
import FiltersWrapper from 'components/FiltersWrapper';
import Card from '../../Components/EventCard';
// import basketball from '../../../assets/img/basketball3.png';
import { useTournamentSportList } from '../../queries/hooks';
// import ListLoader from 'components/ListLoader';
import Loader from 'components/Loader';
import SelectList from 'components/select-list';

const categoryList = ['one', 'two', 'three'];
export default function Index() {
  const [searchedSport, setSearchedSport] = useState('');
  const [sportsToShow, setSportsToShow] = useState([]);
  const [sport, setSport] = useState(null);
  const [type, setType] = useState(null);
  const [status, setStatus] = useState(null);

  const data = [
    {
      selectedValue: sport,
      setSelectedValue: setSport,
      placeholder: 'Sport',
      listData: categoryList,
    },
    {
      selectedValue: type,
      setSelectedValue: setType,
      placeholder: 'Date',
      listData: categoryList,
    },
    {
      selectedValue: status,
      setSelectedValue: setStatus,
      placeholder: 'Status',
      listData: categoryList,
    },
  ];

  const sportData = useTournamentSportList({
    tournamentId: location.pathname.split('/')[2],
  });

  useEffect(() => {
    if (sportData.isFetched)
      // console.log(sportData?.data);
      setSportsToShow(sportData?.data?.filter((item) => item.title.includes(searchedSport)));
    // setSportsToShow(sportData?.data?.filter(item => item.sport.includes(searchedSport)));
  }, [searchedSport, sportData.isLoading]);

  // console.log(sportsToShow);
  // console.log(sportData);
  return (
    <div className="md:mt-8 pt-4 md:pt-0 px-4 md:px-16 bg-white pb-44 mx-auto max-w-fhd">
      <h1 className="my-3 font-bold text-lg">Sport List</h1>
      <FiltersWrapper filters={data} />

      <div className="flex gap-2 justify-end items-center">
        {/* <input
          type="text"
          placeholder="Search Sports"
          className="w-full md:w-60 rounded-lg border-b-2 border-gray-400 focus:outline-none focus:border-gray-500"
          value={searchedSport}
          onChange={(e) => setSearchedSport(e.target.value.toLocaleLowerCase())}
        /> */}

        <SelectList
          dataCy="sport_selector"
          selectedValue={searchedSport}
          setSelectedValue={setSearchedSport}
          placeholder="Sport"
          listData={sportData?.data?.map((sport) => sport.title)}
          fullWidth={true}
          filterClose={true}
          setFilterPopup={() => {}}
        />
      </div>
      {/* {console.log(sportData)} */}
      {sportData.isLoading && <CustomLoader />}
      {sportsToShow?.length === 0 ? <div className="text-center w-full my-16"></div> : null}
      <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-7 mt-10 gap-2 lg:gap-4">
        {sportData.isFetched &&
          sportData.data?.length > 0 &&
          sportsToShow.map((item, index) => (
            <Card
              data={item}
              redirectTo={`/micro-site/${location.pathname.split('/')[2]}/landing/sports/${
                item.title
              }`}
              key={index}
            />
          ))}
        {/* <ListLoader queryData={sportData} queryKey="title" 
        CustomLoader={CustomLoader}
        >
          {({ item, index }) => {
            return (
              <Card
                data={item}
                redirectTo={`/micro-site/${location.pathname.split('/')[2]}/landing/sports/${
                  item.title
                }`}
                key={index}
              />
            );
          }}
        </ListLoader> */}
        {
          // sportData.map((el, id) => {
          // {/*  return <Card data={el} key={id} redirectTo={`/micro-site/landing/sports/basketball`} />;*/}
          // {/*})}*/}
        }
      </div>
    </div>
  );
}

const CustomLoader = () => {
  return (
    <div className="w-full flex justify-center min-h-screen mt-4">
      <Loader className={'mt-4'} />
    </div>
  );
};
