import React, { useState } from 'react';
// import playerImg from '../../assets/img/tennis_player.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faCircle, faImage, faPlayCircle } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
// import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useFolderDetails } from '../queries/hooks';
import DetailItemLoader from '../../components/DetailItemLoader';
import ShowMedia from '../Landing/Gallery/components/showMedia';

export default function GalleryCard({ liveEvent, data, redirectTo, redirectToState }) {
  const [hoverActive, setHoverActive] = useState(false);
  const history = useHistory();
  const folderDetailsQuery = useFolderDetails({
    folder_name: data.name,
  });

  /*
      useEffect(() => {
        if (folderDetailsQuery.data) {
          console.log(folderDetailsQuery.data);
        }
      }, [folderDetailsQuery.data]);
    */
  /*
        const mediaList = useMediaList({
          folder_name: data.name,
        });
        */

  return (
    <>
      <DetailItemLoader queryData={folderDetailsQuery}>
        {({ data: item }) => {
          return (
            <div
              onContextMenu={(e) => e.preventDefault()}
              className={`hidden lg:block h-52 ${liveEvent ? 'h-40' : 'md:h-72'} basis-${
                item.preview_width_desktop === '50%'
                  ? 6
                  : item.preview_width_desktop === '25%'
                  ? 3
                  : item.preview_width_desktop === '33%'
                  ? 4
                  : item.preview_width_desktop === '66%'
                  ? 8
                  : item.preview_width_desktop === '75%'
                  ? 9
                  : item.preview_width_desktop === '100%'
                  ? 12
                  : 6
              }/12  relative rounded-lg overflow-hidden cursor-pointer `}
              onClick={() => history.push(redirectTo)}
              onMouseOver={() => setHoverActive(true)}
              onMouseLeave={() => setHoverActive(false)}
            >
              <ShowMedia
                mediaName={item.preview_image}
                ImageOnly
                className="w-full h-full object-cover"
              />

              {liveEvent ? (
                <div className="flex flex-col md:flex-row md:items-end justify-between absolute top-4 bottom-4 left-3 right-3">
                  <div>
                    <div className="md:hidden">
                      <h4 className="text-white text-xs font-bold mt-1">{item.name}</h4>
                      <p className="text-white text-xxs ">Finals - U19</p>
                    </div>

                    <div className="hidden md:block">
                      <h4 className="text-white text-sm font-bold mt-1">{item.name}</h4>
                    </div>
                  </div>
                  <div>
                    <div className="flex gap-2.5 items-center">
                      <p className="text-white text-xs">View Schedule</p>
                      <FontAwesomeIcon icon={faArrowRight} size="xs" color="#fff" />
                    </div>
                  </div>
                </div>
              ) : (
                <div className="flex flex-col md:justify-end justify-between absolute top-4 bottom-4 left-3 right-3 md:left-8 md:bottom-8 md:top-8 md:right-8">
                  {/* <div className="flex gap-1.5 md:hidden items-center"> */}
                  {/*  {!video ? ( */}
                  {/*    <> */}
                  {/*      <FontAwesomeIcon icon={faImage} className="w-3 text-white" /> */}
                  {/*      <p className="text-white text-xxs">{item.images.length} Photos</p> */}
                  {/*    </> */}
                  {/*  ) : ( */}
                  {/*    <> */}
                  {/*      <FontAwesomeIcon icon={faPlayCircle} /> */}
                  {/*      <p className="text-white text-xxs">{item.images.length} Videos</p> */}
                  {/*    </> */}
                  {/*  )} */}
                  {/* </div> */}

                  <div
                    className={`${hoverActive ? 'flex justify-between items-end flex-wrap' : ''}`}
                  >
                    <div>
                      <h4 className={`text-white text-xs md:text-2xl font-bold mt-1`}>
                        {item.folder_name}
                      </h4>
                      {item.date && (
                        <p
                          className={`text-white text-xxs md:text-sm ${
                            hoverActive ? 'hidden' : ''
                          }`}
                        >
                          {new Date(item.date).toLocaleDateString('en-UK')}
                        </p>
                      )}
                      {hoverActive ? (
                        <>
                          <div className={'flex gap-1'}>
                            {item.number_of_images !== 0 && (
                              <>
                                <FontAwesomeIcon icon={faImage} className="w-3 text-white" />
                                <p className="text-white text-xxs">
                                  {item.number_of_images} Photo{item.number_of_images > 1 && `s`}
                                </p>
                              </>
                            )}
                            {item.number_of_videos !== 0 && (
                              <>
                                <FontAwesomeIcon icon={faPlayCircle} className="w-3 text-white" />
                                <p className="text-white text-xxs">
                                  {item.number_of_videos} Video{item.number_of_videos > 1 && `s`}
                                </p>
                              </>
                            )}
                          </div>
                          <div className="flex flex-wrap gap-2 text-sm text-white items-center">
                            {item.age_category && (
                              <p className="text-white text-xs md:text-sm">{item.age_category}</p>
                            )}
                            {((item.age_category && item.gender) ||
                              (item.gender && item.date) ||
                              (item.age_category && item.date)) && (
                              <FontAwesomeIcon icon={faCircle} className="w-1 h-1" />
                            )}
                            {item.gender && (
                              <p className="text-white text-xs md:text-sm">{item.gender}</p>
                            )}
                            {item.gender && item.age_category && item.date && (
                              <FontAwesomeIcon icon={faCircle} className="w-1 h-1" />
                            )}
                            {item.date && (
                              <p className="text-white text-xs md:text-sm">
                                {new Date(item.date).toLocaleDateString('en-UK')}
                              </p>
                            )}
                          </div>
                        </>
                      ) : null}
                    </div>
                    {hoverActive ? (
                      <div className="mt-8">
                        <button
                          className="flex items-center text-white px-4 py-2 border-1 border-white rounded-lg gap-2"
                          onClick={() => history.push(redirectTo, redirectToState)}
                        >
                          <p className="font-bold">
                            {item.number_of_images <= 0 && item.number_of_videos > 0
                              ? 'Watch Videos'
                              : item.number_of_images > 0 && item.number_of_videos <= 0
                              ? 'View More Photos'
                              : item.number_of_images > 0 && item.number_of_videos > 0
                              ? 'View More Photos and Videos'
                              : null}
                          </p>
                          <FontAwesomeIcon icon={faArrowRight} />
                        </button>
                      </div>
                    ) : null}
                  </div>
                </div>
              )}
            </div>
          );
        }}
      </DetailItemLoader>
    </>
  );
}
