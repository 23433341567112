import React, { useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faPlayCircle } from '@fortawesome/free-solid-svg-icons';
// import GalleryCard from '../../../Components/GalleryCard';
// import GalleryIcon from '../../../../assets/img/gallery_icon.svg';
import { useFolderDetails, useMediaDetails, useMediaList } from '../../../queries/hooks';
import ListLoader from '../../../../components/ListLoader';
import DetailItemLoader from '../../../../components/DetailItemLoader';
import Modal from './Modal';

export default function IndividualEvent({ userRole }) {
  const history = useHistory();
  const match = useRouteMatch('/micro-site/:tournament/landing/gallery/:section/:folder');
  const profilePageMatch = useRouteMatch(
    `/${userRole}/profiles/tournaments/:tournamentId/gallery/:section/:folder`
  );

  const [viewAll, setViewAll] = useState(false);
  // const data = {
  //   sport_name: 'Swimming',
  //   gender: 'male',
  //   age_category: 'U-19',
  //   event: 'Swimming Finals - U19',
  //   date: 'October 21, 2021',
  //   images: [
  //     {
  //       id: 0,
  //       img_url: Swimming,
  //     },
  //     {
  //       id: 0,
  //       img_url: swimmingImg1,
  //     },
  //     {
  //       id: 0,
  //       img_url: swimmingImg2,
  //     },
  //     {
  //       id: 0,
  //       img_url: swimmingImg3,
  //     },
  //     {
  //       id: 0,
  //       img_url: swimmingImg4,
  //     },
  //     {
  //       id: 0,
  //       img_url: swimmingImg5,
  //     },
  //     {
  //       id: 0,
  //       img_url: swimmingImg6,
  //     },
  //   ],
  //   videos: [],
  // };
  // const withVideoData = {
  //   sport_name: 'Athletics',
  //   gender: 'male',
  //   age_category: 'U-19',
  //   event: 'Swimming Finals - U19',
  //   date: 'October 21, 2021',
  //   images: [
  //     {
  //       id: 0,
  //       img_title: '100m Sprint',
  //       img_description: 'Qualifying Round',
  //       img_url: athlete2,
  //     },
  //     {
  //       id: 0,
  //       img_title: '100m Sprint',
  //       img_description: 'Qualifying Round',
  //       img_url: athlete3,
  //     },
  //     {
  //       id: 0,
  //       img_title: '100m Sprint',
  //       img_description: 'Qualifying Round',
  //       img_url: athlete4,
  //     },
  //     {
  //       id: 0,
  //       img_title: '100m Sprint',
  //       img_description: 'Qualifying Round',
  //       img_url: athlete5,
  //     },
  //     {
  //       id: 0,
  //       img_title: '100m Sprint',
  //       img_description: 'Qualifying Round',
  //       img_url: athlete6,
  //     },
  //     {
  //       id: 0,
  //       img_title: '100m Sprint',
  //       img_description: 'Qualifying Round',
  //       img_url: athlete2,
  //     },
  //   ],
  //   videos: [],
  // };

  const folderDetails = useFolderDetails({
    folder_name: `${match?.params?.section || profilePageMatch?.params?.section}-${
      match?.params?.folder || profilePageMatch?.params?.folder
    }`,
  });
  const folderMediaList = useMediaList({
    folder_name: `${match?.params?.section || profilePageMatch?.params?.section}-${
      match?.params?.folder || profilePageMatch?.params?.folder
    }`,
  });
  console.log(folderDetails, folderMediaList);
  return (
    <div className="px-4 md:px-16 pb-14 max-w-fhd mx-auto bg-white">
      <div
        className="flex gap-2 md:gap-4 items-center text-gray-750 font-semibold cursor-pointer md:shadow-shadowBottom md:mb-6 md:pt-8 md:pb-2 pt-2"
        onClick={() => {
          history?.location?.state?.fromAttractions || profilePageMatch
            ? history.goBack()
            : history.goBack(`/micro-site/${match.params.tournament}/landing/gallery`);
        }}
      >
        <FontAwesomeIcon icon={faAngleLeft} />
        <h3 className="text-lg md:text-2xl font-bold text-gray-750 font-poppins">
          <DetailItemLoader queryData={folderDetails}>
            {({ data }) => data?.folder_name || ''}
          </DetailItemLoader>
        </h3>
      </div>
      <hr className="w-16 md:hidden mx-0 my-0 md:w-60 bg-gray-750 h-0.5 md:h-1 mt-1 md:mt-6" />
      <div className="grid grid-cols-2 lg:grid-cols-3 gap-4 md:gap-6 lg:gap-10 mt-4 md:mt-0 relative auto-rows-min">
        <ListLoader
          queryData={folderMediaList}
          queryKey={'name'}
          EmptyMessage={'Stay tuned , Images and Videos will be updated shortly'}
          showLimited={!viewAll}
          EmptyMessageStyle={{
            position: 'absolute',
            margin: 'auto',
            backgroundColor: '#fff',
            padding: '10px',
          }}
        >
          {(item, index) => {
            return <IndividualCard key={index} index={index} data={item.item} />;
          }}
        </ListLoader>
      </div>
      <DetailItemLoader queryData={folderMediaList}>
        {({ data }) => {
          if (data.length > 10) {
            return (
              <div className="mt-4 flex justify-center items-center">
                <button
                  className="w-full md:w-1/3 reg-btn-dark text-white font-bold py-2 px-4 rounded-lg"
                  onClick={() => setViewAll(!viewAll)}
                >
                  {viewAll ? 'View Less' : 'View All'}
                </button>
              </div>
            );
          }
          return null;
        }}
      </DetailItemLoader>

      {/*
      <div
        className="text-gray-750 font-semibold cursor-pointer md:shadow-shadowBottom md:mb-6 md:pt-24 md:pb-2 pt-2"
        onClick={() => {
          history.goBack();
        }}
      >
        <h3 className="text-lg md:text-2xl font-bold text-gray-750 font-poppins">
          You might also like
        </h3>
        <hr className="w-16 md:hidden mx-0 my-0 md:w-60 bg-gray-750 h-0.5 md:h-1 mt-1 md:mt-6" />
      </div>
*/}
    </div>
  );
}

function IndividualCard({ _index, data }) {
  const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState({});

  const mediaDetails = useMediaDetails({
    media_item: data.name,
  });

  useEffect(() => {
    if (!mediaDetails.isLoading) {
      console.log(mediaDetails.data);
    }
  }, [mediaDetails]);

  return (
    <>
      <DetailItemLoader queryData={mediaDetails}>
        {({ data }) => {
          return (
            <div
              onClick={() => {
                setModal(true);
                setModalData(data);
              }}
              className="cursor-pointer relative card-bg rounded-lg overflow-hidden"
            >
              {data.type.toLowerCase().includes('video') && (
                <div
                  className={
                    'absolute top-0 left-0 h-full w-full flex justify-center items-center opacity-70 rounded-lg'
                  }
                >
                  <FontAwesomeIcon icon={faPlayCircle} className=" text-gray-100 z-3 text-5xl" />
                </div>
              )}
              <img
                onContextMenu={(e) => e.preventDefault()}
                src={
                  data.type === 'Image'
                    ? data.image
                    : data.type === 'Video(youtube)'
                    ? `https://ytimg.googleusercontent.com/vi/${data.video_id}/hqdefault.jpg`
                    : data.type === 'Video(vimeo)'
                    ? `https://vumbnail.com/${data.video_id}.jpg`
                    : null
                }
                alt=""
                className="h-full w-full object-cover"
              />
              <div className="absolute ml-4 bottom-4">
                <h3 className="text-white text-xs md:text-lg font-bold mt-1 font-poppins">
                  {data.media_name}
                </h3>
                {/* <p className="text-white text-xxs md:text-xs md:mt-1">{item.img_description}</p> */}
              </div>
            </div>
          );
        }}
      </DetailItemLoader>
      {modal ? (
        <Modal setModalClose={setModal} data={modalData} fullImage={data.type === 'Image'} />
      ) : null}
    </>
  );
}
