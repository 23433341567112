import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import React, { useRef } from 'react';
import { useOutsideClick } from 'utils/HooksUtils';
import ShowMedia from './showMedia';

const Modal = ({ setModalClose, data, raw, fullImage }) => {
  const divRef = useRef(null);
  useOutsideClick(divRef, () => setModalClose(false));

  return (
    <div className="fixed inset-0 flex flex-col items-center justify-center z-20 bg-gray-450 backdrop-blur-2xl backdrop-brightness-150 ">
      <div
        className=" w-full max-h-90vh md:max-w-2/3 relative flex items-center justify-center"
        style={{ aspectRatio: '16/9' }}
      >
        {!raw ? (
          <ShowMedia
            mediaName={data.name}
            className={'max-h-88vh object-contain border-5 border-white'}
            divRef={divRef}
            fullImage
          />
        ) : data?.type === 'Video(youtube)' ? (
          <iframe
            ref={divRef}
            className={'w-full h-auto md:w-auto md:h-full border-5 border-white'}
            style={{
              aspectRatio: '16/9',
            }}
            src={`https://www.youtube.com/embed/${data?.video_id}?autoplay=1`}
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        ) : data?.type === 'Video(vimeo)' ? (
          <iframe
            ref={divRef}
            src={`https://player.vimeo.com/video/${data?.video_id}?autoplay=1`}
            className={'w-full h-auto md:w-auto md:w-full border-5 border-white bg-white'}
            style={{
              aspectRatio: '16/9',
            }}
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
          />
        ) : null}
      </div>
    </div>
  );
};

export default Modal;
