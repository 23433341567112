import React, { useRef } from 'react';
import { useFolderDetails, useFolderList, useSectionDetails } from '../../../queries/hooks';
import DetailItemLoader from '../../../../components/DetailItemLoader';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ListLoader from '../../../../components/ListLoader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import GalleryCard from '../../../Components/GalleryCard';
import { useHistory, useRouteMatch } from 'react-router-dom';
import Loader from '../../../../components/Loader';
import ShowMedia from './showMedia';
import GalleryCardMobile from '../../../Components/GalleryCardMobile';

const Section = ({ sectionName = 'Section', userRole }) => {
  const history = useHistory();
  const match = useRouteMatch('/micro-site/:tournament/landing/gallery');
  const profilePageMatch = useRouteMatch(`/${userRole}/profiles/tournaments/:tournamentId/gallery`);
  // console.log(sectionName);
  const sliderRef = useRef(null);
  const settings = {
    className: 'center',
    centerMode: true,
    // slidesToShow: 2,
    initialSlide: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 1240,
        settings: {
          className: 'center',
          centerMode: true,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          className: 'center',
          centerMode: true,
          centerPadding: '10%',
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          className: 'center',
          centerMode: true,
          centerPadding: '10%',
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const sectionDetailsQuery = useSectionDetails({
    section_name: sectionName,
  });
  const sectionFolderListQuery = useFolderList({
    section_name: sectionName,
  });

  return (
    <DetailItemLoader queryData={sectionDetailsQuery}>
      {({ data: section }) => {
        return (
          <div className="mt-10 md:mt-16">
            <div className="flex justify-between items-center md:shadow-shadowBottom md:mb-6 md:py-2 pt-2">
              <h3 className="text-base md:text-2xl font-bold text-gray-750 normal-case md:uppercase font-poppins">
                {section.name}
              </h3>
              <div
                className="flex text-xs md:text-base gap-1 md:gap-2 items-center text-gray-750 font-semibold cursor-pointer border-1 border-gray-750 rounded md:rounded-none md:border-0 px-2 py-1 md:px-0 md:py-0"
                onClick={() =>
                  history.push(`${match?.url || profilePageMatch?.url}/${sectionName}`)
                }
              >
                <p>View All</p>
                <FontAwesomeIcon icon={faAngleRight} />
              </div>
            </div>
            <hr className="w-16 md:hidden mx-0 my-0 md:w-60 bg-gray-750 h-0.5 md:h-1 mt-1 md:mt-6" />

            {section.preview_type === 'Mesh' ? (
              <>
                <div className="hidden lg:flex mt-6 justify-center flex-wrap gap-1.5">
                  <ListLoader
                    queryData={sectionFolderListQuery}
                    queryKey={'name'}
                    showLimited
                    limit={section.preview_count}
                    EmptyMessage={'Stay tuned , Images and Videos will be updated shortly'}
                  >
                    {(item, index) => {
                      // console.log(item.data);
                      // return <></>;
                      return (
                        <GalleryCard
                          key={index}
                          data={item.data}
                          redirectTo={
                            !profilePageMatch
                              ? `/micro-site/${
                                  location.pathname.split('/')[2]
                                }/landing/gallery/${sectionName}/${item.data.name
                                  .split('-')
                                  .at(-1)}`
                              : `${profilePageMatch.url}/${sectionName}/${item.data.name
                                  .split('-')
                                  .at(-1)}`
                          }
                        />
                      );
                    }}
                  </ListLoader>
                </div>

                <div className="mt-6 justify-center flex flex-wrap gap-1.5 lg:hidden">
                  <ListLoader
                    queryData={sectionFolderListQuery}
                    queryKey={'name'}
                    showLimited
                    limit={section.preview_count}
                    EmptyMessage={'Stay tuned , Images and Videos will be updated shortly'}
                  >
                    {(item, idx) => (
                      <GalleryCardMobile
                        key={idx}
                        data={item.data}
                        redirectTo={
                          !profilePageMatch
                            ? `/micro-site/${
                                location.pathname.split('/')[2]
                              }/landing/gallery/${sectionName}/${item.data.name.split('-').at(-1)}`
                            : `${profilePageMatch.url}/${sectionName}/${item.data.name
                                .split('-')
                                .at(-1)}`
                        }
                      />
                    )}
                  </ListLoader>
                </div>
              </>
            ) : (
              <div className="mt-6 main-slider">
                <div className="relative">
                  <button
                    onClick={() => {
                      sliderRef.current && sliderRef.current.slickNext();
                    }}
                    className="hidden md:inline-block absolute -right-4 bg-white rounded-full h-8 w-8 shadow-lg z-10 transform -translate-y-1/2  top-1/2 cursor-pointer"
                  >
                    <FontAwesomeIcon icon={faAngleRight} className="text-gray-450" />
                  </button>
                  <button
                    onClick={() => {
                      sliderRef.current && sliderRef.current.slickPrev();
                    }}
                    className="hidden md:inline-block  absolute left-0 bg-white rounded-full h-8 w-8 shadow-lg z-10 top-1/2 cursor-pointer transform -translate-y-1/2 -translate-x-1/2"
                  >
                    <FontAwesomeIcon icon={faAngleLeft} className="text-gray-450" />
                  </button>
                  <Slider
                    ref={sliderRef}
                    slidesToShow={
                      sectionFolderListQuery.isLoading
                        ? 1
                        : sectionFolderListQuery.data.length >= 3
                        ? 3
                        : sectionFolderListQuery.data.length
                    }
                    {...settings}
                  >
                    {sectionFolderListQuery.isLoading ? (
                      <Loader />
                    ) : (
                      sectionFolderListQuery.data.map((item, index) => {
                        const folderDetails = useFolderDetails({ folder_name: item.name });

                        return (
                          <DetailItemLoader queryData={folderDetails} key={index}>
                            {({ data }) => {
                              console.log(data);
                              return (
                                <div
                                  onContextMenu={(e) => e.preventDefault()}
                                  className="card-bg cursor-pointer w-full h-52 md:h-60 md:mx-4 carousel-item relative rounded-lg"
                                  onClick={() =>
                                    history.push(
                                      `${
                                        match?.url || profilePageMatch?.url
                                      }/${sectionName}/${data.name.split('-').at(-1)}`
                                    )
                                  }
                                >
                                  <ShowMedia
                                    className="w-full h-full main_img block m-auto object-cover rounded-lg"
                                    mediaName={data.preview_image}
                                    ImageOnly
                                  />
                                  <div className="flex flex-col md:items-end md:flex-row  justify-between absolute top-4 bottom-4 md:bottom-8 md:left-8 left-3 right-3">
                                    <div>
                                      <h4 className="text-white text-xs md:text-lg font-bold mt-1">
                                        {data.folder_name}
                                      </h4>
                                      <p className="text-white text-xxs md:text-xs md:mt-1"></p>
                                    </div>
                                    <p className="text-white text-xs md:text-sm md:hidden">
                                      View More
                                    </p>
                                  </div>
                                </div>
                              );
                            }}
                          </DetailItemLoader>
                        );
                      })
                    )}
                  </Slider>
                </div>
              </div>
            )}
          </div>
        );
      }}
    </DetailItemLoader>
  );
};

export default Section;
