import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import Overview from './Overview';
import Sports from './Sports';
import Results from './Results';
import DrawsAndSchedules from './DrawsAndSchedule';
import Attractions from './Attractions';
import Gallery from './Gallery';
import Faq from './FAQ';
import Reconciliation from './Reconciliation';
export default function index() {
  const match = useRouteMatch('/micro-site/:tournament_id');
  return (
    <div className="bg-white">
      <Switch>
        <Route path={`${match.url}/landing/draws-schedules`}>
          <DrawsAndSchedules enabledScheduled={true} />
        </Route>
        <Route path={`${match.url}/landing/overview`} component={Overview} />
        <div className="md:py-8">
          <Route path={`${match.url}/landing/sports`} component={Sports} />
          <Route path={`${match.url}/landing/results`} component={Results} />
          <Route path={`${match.url}/landing/attractions`} component={Attractions} />
          <Route path={`${match.url}/landing/gallery`} component={Gallery} />
          <Route path={`${match.url}/landing/faq`} component={Faq} />
          <Route path={`${match.url}/landing/reconciliation`} component={Reconciliation} />
        </div>
      </Switch>
    </div>
  );
}
