import React, { useEffect, useRef } from 'react'; //
import { NavLink, matchPath, useLocation } from 'react-router-dom';

export default function Tabs({ tabList, color, border, baseUrl, page, withinCreateorManage }) {
  const scrollRef = useRef(null);
  const elementRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    if (elementRef.current && elementRef.current.offsetLeft > scrollRef.current.offsetLeft) {
      scrollRef.current.scrollTo({
        left: elementRef.current.offsetLeft - elementRef.current.clientWidth,
      });
    }
  }, []);
  const RouteToMatch = tabList?.find((el, i) =>
    matchPath(location.pathname, {
      path: baseUrl + '/' + el.route,
    })
  );

  if (withinCreateorManage) return null;
  return (
    <div className="no-scrollbar">
      <div
        ref={scrollRef}
        className="flex w-full mt-6 lg:mt-0 lg:mx-0 font-roboto profile-subnav justify-between cursor-pointer bg-white text-center overflow-x-auto no-scrollbar"
      >
        {tabList.map((el, idx) => {
          return (
            <NavLink
              ref={RouteToMatch?.route === el?.route ? elementRef : { current: null }}
              to={{ pathname: `${baseUrl}/${el.route}`, state: el?.state || {} }}
              key={idx}
              activeClassName={`${color} ${border} border-b-4 font-bold flex-grow min-w-max cursor-pointer px-4 py-2`}
              className={
                el.disabled
                  ? 'text-gray-450 min-w-max border-b-1 border-gray-450 flex-grow disabled px-4 py-2 opacity-50'
                  : 'text-gray-450 min-w-max border-b-1 border-gray-450 flex-grow cursor-pointer px-4 py-2'
              }
              data-cy="nav_navbar"
            >
              {el.name}
            </NavLink>
          );
        })}
      </div>
    </div>
  );
}
