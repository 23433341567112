import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faCircle, faImage, faPlayCircle } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
// import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useFolderDetails } from '../queries/hooks';
import DetailItemLoader from '../../components/DetailItemLoader';
import ShowMedia from '../Landing/Gallery/components/showMedia';

export default function GalleryCardMobile({ liveEvent, data, redirectTo, redirectToState }) {
  const history = useHistory();
  const [hoverActive, setHoverActive] = useState(false);

  const folderDetailsQuery = useFolderDetails({
    folder_name: data.name,
  });

  return (
    <DetailItemLoader queryData={folderDetailsQuery}>
      {({ data: item }) => {
        return (
          <div
            onContextMenu={(e) => e.preventDefault()}
            className={`lg:hidden cursor-pointer h-52 basis-${
              item.preview_width_mobile === '50%'
                ? 6
                : item.preview_width_mobile === '25%'
                ? 3
                : item.preview_width_mobile === '33%'
                ? 4
                : item.preview_width_mobile === '66%'
                ? 8
                : item.preview_width_mobile === '75%'
                ? 9
                : item.preview_width_mobile === '100%'
                ? 12
                : 6
            }/12 live-event-card relative rounded-lg overflow-hidden`}
            onMouseOver={() => setHoverActive(true)}
            onMouseLeave={() => setHoverActive(false)}
          >
            {/* <img src={item.images[0].img_url} className="w-full h-full object-cover" alt="" /> */}
            <ShowMedia
              className={'h-full w-full object-cover'}
              ImageOnly
              mediaName={item.preview_image}
            />
            {liveEvent ? (
              <div className="flex flex-col lg:flex-row lg:items-end justify-between absolute top-4 bottom-4 left-3  right-3">
                <div>
                  <div className="lg:hidden">
                    <h4 className="text-white text-xs font-bold mt-1">{item.folder_name}</h4>
                    {/* <p className="text-white text-xxs  ">Finals - U19</p> */}
                  </div>
                </div>
                <div>
                  <div className="flex gap-2.5 items-center">
                    <p className="text-white text-xs">View Schedule</p>
                    <FontAwesomeIcon icon={faArrowRight} size="xs" color="#fff" />
                  </div>
                </div>
              </div>
            ) : (
              <div
                className="flex flex-col justify-between absolute top-4 bottom-4 left-3 right-3"
                onClick={() => history.push(redirectTo, redirectToState)}
              >
                <div className="flex gap-1.5 items-center">
                  {item.number_of_images > 0 && (
                    <>
                      <FontAwesomeIcon icon={faImage} className="w-3 text-white" />
                      <p className="text-white text-xxs">
                        {item.number_of_images} Photo{item.number_of_images > 1 && `s`}
                      </p>
                    </>
                  )}
                  {item.number_of_videos > 0 && item.number_of_images > 0 && (
                    <FontAwesomeIcon icon={faCircle} className="w-1 text-white" />
                  )}

                  {item.number_of_videos > 0 && (
                    <>
                      <FontAwesomeIcon icon={faPlayCircle} className="w-3 text-white" />
                      <p className="text-white text-xxs">
                        {item.number_of_videos} Video{item.number_of_videos > 1 && `s`}
                      </p>
                    </>
                  )}
                </div>

                <div>
                  <h4 className={`text-white text-xs font-bold mt-1`}> {item.folder_name} </h4>
                  {item.date && (
                    <p className={`text-white text-xxs`}>
                      {new Date(item.date).toLocaleDateString('en-UK')}
                    </p>
                  )}
                </div>

                {hoverActive ? (
                  <div className="absolute right-2 bottom-2">
                    <button
                      className="flex items-center text-white px-4 py-2 border-1 border-white rounded-lg gap-2"
                      onClick={() => history.push(redirectTo, redirectToState)}
                    >
                      <p className="font-bold">
                        {item.number_of_images <= 0 && item.number_of_videos > 0
                          ? 'Watch Videos'
                          : item.number_of_images > 0 && item.number_of_videos <= 0
                          ? 'View More Photos'
                          : item.number_of_images > 0 && item.number_of_videos > 0
                          ? 'View More Photos and Videos'
                          : null}
                      </p>
                      <FontAwesomeIcon icon={faArrowRight} />
                    </button>
                  </div>
                ) : null}
              </div>
            )}
          </div>
        );
      }}
    </DetailItemLoader>
  );
}
